<template>
  <div>
    <v-row>
      <v-col sm="6" cols="12">
        <Indicators />
      </v-col>
      <v-col sm="6" cols="12">
        <Comment />
      </v-col>
      <v-col sm="6" cols="12">
        <IndicatorMark :sessionId="sessionId" />
      </v-col>
      <v-col sm="6" cols="12">
        <IndicatorComment :sessionId="sessionId" />
      </v-col>
    </v-row>
    <v-row justify="end" class="mt-3 mr-0 ml-0">
      <v-btn
        v-if="!isFirstIndicator && !isEmptyCompetences"
        :class="['white--text primary']"
        @click="setPrevIndicator"
        min-width="220px"
      >
        Предыдущий индикатор
      </v-btn>
      <v-btn
        v-if="isFirstIndicator && !isFirstTask"
        class="white--text primary"
        min-width="220px"
        @click="switchTask(false)"
      >
        К предыдущему заданию
      </v-btn>
      <v-btn
        v-if="!isLastIndicator && !isEmptyCompetences"
        :class="['white--text primary ml-8']"
        @click="setNextIndicator"
        min-width="220px"
      >
        Следующий индикатор
      </v-btn>
      <v-btn
        v-if="!isLastTask && (isLastIndicator || isEmptyCompetences)"
        :class="['white--text primary ml-8', { hidden: isLastTask }]"
        @click="switchTask(true)"
        min-width="220px"
      >
        К следующему заданию
      </v-btn>
      <v-btn
        v-if="
          (isLastTask && isLastIndicator) || (isLastTask && isEmptyCompetences)
        "
        :class="['white--text primary ml-8']"
        min-width="220px"
        @click="updateReport(), switchView('report')"
      >
        К составлению отчета
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { computed, inject, provide } from "@vue/composition-api";

import Indicators from "./choice/Indicators";
import Comment from "./choice/Comment";
import IndicatorMark from "./choice/IndicatorMark";
import IndicatorComment from "./choice/IndicatorComment";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const sessionId = computed(() => store.getters.getSessionId);

    const task = computed(() => store.getters.getCurrentAssessmentTask);
    const selectedCompetence = computed(
      () => store.getters.getSelectedCompetence || {}
    );
    const selectedIndicator = computed(
      () => store.getters.getSelectedIndicator || {}
    );

    const isEmptyCompetences = computed(
      () => !selectedIndicator.value.id && !selectedCompetence.value.id
    );
    provide("isEmptyCompetences", isEmptyCompetences);

    const indicatorIndex = computed(() =>
      selectedCompetence.value.indicators?.findIndex(
        el => el.id === selectedIndicator.value?.id
      )
    );

    const competenceIndex = computed(() =>
      task.value.competences?.findIndex(
        el => el.id === selectedCompetence.value?.id
      )
    );

    const isFirstIndicator = computed(
      () => indicatorIndex.value === 0 && competenceIndex.value === 0
    );

    const isLastIndicatorInCompetence = computed(
      () =>
        indicatorIndex.value + 1 === selectedCompetence.value.indicators?.length
    );

    const isLastIndicator = computed(
      () =>
        competenceIndex.value + 1 === task.value.competences?.length &&
        isLastIndicatorInCompetence.value
    );

    const setPrevIndicator = () => {
      if (indicatorIndex.value === 0) {
        store.commit(
          "setSelectedCompetence",
          task.value.competences[competenceIndex.value - 1]
        );
        store.commit(
          "setSelectedIndicator",
          selectedCompetence.value.indicators[
            selectedCompetence.value.indicators.length - 1
          ]
        );
      } else {
        store.commit(
          "setSelectedIndicator",
          selectedCompetence.value.indicators[indicatorIndex.value - 1]
        );
      }
    };

    const setNextIndicator = () => {
      if (isLastIndicatorInCompetence.value) {
        store.commit(
          "setSelectedCompetence",
          task.value.competences[competenceIndex.value + 1]
        );
        store.commit(
          "setSelectedIndicator",
          selectedCompetence.value.indicators[0]
        );
      } else {
        store.commit(
          "setSelectedIndicator",
          selectedCompetence.value.indicators[indicatorIndex.value + 1]
        );
      }
    };

    const isFirstTask = computed(
      () =>
        store.getters.getAssessmentTasks.findIndex(
          el => el.id === task.value.id
        ) === 0
    );

    const isLastTask = computed(
      () =>
        store.getters.getAssessmentTasks.findIndex(
          el => el.id === task.value.id
        ) +
          1 ===
        store.getters.getAssessmentTasks.length
    );

    const switchTask = inject("switchTask");
    const switchView = inject("switchView");

    const updateReport = async () =>
      await store.dispatch("fetchCurrentReport", {
        sessionId: store.getters.getSessionId,
        role: store.getters.getCurrentRole,
        updateMode: true,
      });

    return {
      sessionId,
      isEmptyCompetences,
      isFirstIndicator,
      isLastIndicator,
      setPrevIndicator,
      setNextIndicator,
      isFirstTask,
      isLastTask,
      switchTask,
      switchView,
      updateReport,
    };
  },
  components: {
    Indicators,
    Comment,
    IndicatorMark,
    IndicatorComment,
  },
};
</script>
