<template>
  <div class="pb-2">
    <v-slider
      class="mt-8"
      v-model="selectedMarkIndex"
      :tick-labels="
        indicator.levelMarks.map(el =>
          el.level == 'Нет оценки' ? 'н/д' : el.level
        )
      "
      :max="indicator.levelMarks.length - 1"
      step="1"
      ticks="always"
      track-color="#cfcfcf"
      tick-size="0"
      thumb-size="10"
      @change="$emit('mark-changed', indicator.levelMarks[$event].id)"
    ></v-slider>
    <div class="slider-hint mt-10">
      {{
        indicator.levelMarks[selectedMarkIndex].level == "Нет оценки"
          ? "н/д"
          : indicator.levelMarks[selectedMarkIndex].level
      }}
      -
      {{ indicator.levelMarks[selectedMarkIndex].description }}
    </div>
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: ["indicator"],
  setup(_) {
    const getMarkIndex = () => {
      let index = _.indicator.levelMarks.findIndex(
        el => el.id == (_.indicator.newMark || _.indicator.estimatedLevelMarkId)
      );

      return ~index ? index : 0;
    };

    const selectedMarkIndex = ref(getMarkIndex());

    watch([() => _.indicator, () => _.indicator.newMark], () => {
      selectedMarkIndex.value = getMarkIndex();
      document.querySelector(".slider-hint").style.height = "0";
      document.querySelector(".slider-hint").style.height = "auto";
    });

    return {
      selectedMarkIndex,
    };
  },
};
</script>

<style lang="scss" scoped>
.slider-hint {
  background-color: #888888;
  color: #fff;
  border-radius: 5px;
  min-height: 85px;
  padding: 10px;
  margin-top: 15px;
}
</style>
