<template>
  <v-tabs
    :grow="grow"
    class="tabnav d-flex nav pl-2 pr-1 mr-1 mb-1"
    center-active
  >
    <v-tab
      v-for="tab in tabs"
      :key="tab.name"
      :class="{ active: isActiveTab(tab) }"
      @click="selectTab(tab)"
    >
      <span>{{ tab.label }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    currentTab: String,
    tabs: Array,
    grow: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const selectTab = tab => {
      emit("tab-selected", tab.name);
    };
    const isActiveTab = tab => {
      return _.currentTab === tab.name ? true : false;
    };

    return {
      selectTab,
      isActiveTab,
    };
  },
};
</script>

<style lang="scss">
#app .tabnav {
  .v-tabs-slider-wrapper {
    display: none !important;
  }
  .v-tabs-bar {
    width: 100% !important;
  }
  .v-tab {
    text-transform: initial;
    padding: 0px 12px;
    letter-spacing: 0;
    min-width: 40px;
    font-weight: 600;
    &--active {
      color: #757575;
    }
  }
  .v-slide-group {
    &__prev {
      position: absolute;
      left: -15px;
      top: 13px;
    }
    &__next {
      position: absolute;
      right: -15px;
      top: 13px;
    }
  }
  .active {
    color: #4c5ca7;
  }
}
</style>
