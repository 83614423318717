<template>
  <v-card elevation="4" class="pa-3" height="400" style="overflow-x: hidden">
    <div
      v-if="!isEmptyCompetences"
      class="d-flex justify-space-between align-top"
      style="min-height: 36px"
    >
      <h3 class="title pt-1">
        {{ selectedCompetence.indexNumber }}.
        {{ selectedCompetence.name }}
      </h3>
      <div
        v-if="selectedIndicator.newMark"
        :style="{
          minWidth: '72px',
        }"
      >
        <v-btn icon>
          <v-icon color="#767676" @click="selectedIndicator.newMark = null"
            >mdi-close</v-icon
          >
        </v-btn>
        <v-btn
          icon
          color="#767676"
          @click="updateIndicatorMark(selectedIndicator.newMark)"
        >
          <v-icon color="#767676">mdi-check</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="selectedIndicator.indexNumber">
      <div class="d-flex mb-2">
        <div class="pr-1">
          {{ selectedCompetence.indexNumber }}.{{
            selectedIndicator.indexNumber
          }}
        </div>
        <div>
          {{ selectedIndicator.name }}
        </div>
      </div>
      <Slider :indicator="selectedIndicator" @mark-changed="markChanged" />
    </div>
  </v-card>
</template>

<script>
import { computed, inject } from "@vue/composition-api";

import Slider from "./Slider";

export default {
  props: ["sessionId"],
  setup(_, { root }) {
    const store = root.$store;

    const loading = inject("loading");

    const selectedCompetence = computed(
      () => store.getters.getSelectedCompetence || {}
    );
    const selectedIndicator = computed(
      () => store.getters.getSelectedIndicator || {}
    );

    const updateIndicatorMark = async id => {
      loading.value = true;
      await store.dispatch("setIndicatorMark", {
        taskId: store.getters.getCurrentAssessmentTask.id,
        profileTaskIndicatorId: selectedIndicator.value.id,
        sessionId: _.sessionId,
        levelMarkId: id,
      });
      loading.value = false;
    };

    const markChanged = id => {
      selectedIndicator.value.newMark = id;
      store.commit(
        "updateReportIndicatorComment",
        selectedIndicator.value.levelMarks.find(el => el.id == id).description
      );
    };

    return {
      isEmptyCompetences: inject("isEmptyCompetences"),
      selectedCompetence,
      selectedIndicator,
      updateIndicatorMark,
      markChanged,
    };
  },
  components: { Slider },
};
</script>
