<template>
  <div>
    <v-select
      v-model="selectedTask"
      :items="tasks"
      item-text="name"
      item-value="id"
      return-object
      label="Выберите задание"
      dense
      :style="{
        maxWidth: '341px',
      }"
    />
    <v-row>
      <TabNav :currentTab="currentTab" :tabs="tabs" @tab-selected="selectTab" />
    </v-row>
    <component :is="getComponent" />

    <preloader v-if="loading" class="preloader" />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  inject,
  provide,
} from "@vue/composition-api";
import { useTabs } from "@/use/helpers";
import TabNav from "@/components/shared/assets/TabNav";

import Choise from "./assessment/Choise";
import Task from "./assessment/Task";
import Video from "./assessment/Video";
import Notes from "./assessment/Notes";
import Key from "./assessment/Key";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const loading = inject("loading");

    const tasks = computed(() => store.getters.getAssessmentTasks);
    const selectedTask = ref({});
    const switchTask = forward => {
      const currentIndex = tasks.value.findIndex(
        el => el.id === selectedTask.value.id
      );
      selectedTask.value =
        tasks.value[forward ? currentIndex + 1 : currentIndex - 1];
    };
    provide("switchTask", switchTask);
    watch(
      () => selectedTask.value,
      async val => {
        loading.value = true;
        await store.dispatch("fetchCurrentAssessmentTask", {
          taskId: val.id,
          sessionId: store.getters.getCurrentReport.sessionId,
        });
        loading.value = false;
        selectTab("Choise");
      }
    );

    const { currentTab, selectTab } = useTabs("Choise");
    provide("switchAssessmentTab", selectTab);
    const tabs = [
      {
        name: "Choise",
        label: "Оценка",
      },
      {
        name: "Task",
        label: "Задание",
      },
      {
        name: "Video",
        label: "Видео",
      },
      {
        name: "Notes",
        label: "Заметки",
      },
      {
        name: "Key",
        label: "Ключ",
      },
    ];

    const getComponent = computed(() => {
      switch (currentTab.value) {
        case "Choise":
          return Choise;
        case "Task":
          return Task;
        case "Video":
          return Video;
        case "Notes":
          return Notes;
        case "Key":
          return Key;
      }
    });

    onBeforeMount(async () => {
      await store.dispatch(
        "fetchAssessmentTasks",
        store.getters.getCurrentReport.sessionId
      );
      selectedTask.value = tasks.value[0];
      loading.value = false;
    });

    onBeforeUnmount(() => store.commit("clearAssessmentTasks"));

    return {
      loading,
      selectedTask,
      tasks,
      currentTab,
      selectTab,
      tabs,
      getComponent,
    };
  },
  components: {
    TabNav,
    Choise,
    Task,
    Video,
    Notes,
    Key,
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
}
</style>
