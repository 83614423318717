<template>
  <div>
    <v-card elevation="4" height="55vh" class="pa-3 d-flex flex-column">
      <Nav :tabs="tabs" :currentTab="currentTab" @tab-selected="selectTab" />
      <div class="flex-grow-1 mt-5" style="overflow-y: auto">
        <p v-html="currentText"></p>
      </div>
    </v-card>
    <div class="d-flex justify-end mx-0 mt-5">
      <v-btn
        class="white--text primary"
        min-width="220px"
        @click="switchAssessmentTab('Choise')"
      >
        К оценке индикаторов
      </v-btn>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "@vue/composition-api";

import { useLineBreakReplacer, useTabs } from "@/use/helpers";

import Nav from "@/components/shared/assets/Nav";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const tabs = computed(() =>
      store.getters.getCurrentAssessmentTask.notes.map(el => ({
        name: el.id,
        label: el.author,
      }))
    );

    const { currentTab, selectTab } = useTabs(tabs.value[0].name);
    const { replacer } = useLineBreakReplacer();

    const currentText = computed(() =>
      replacer(
        store.getters.getCurrentAssessmentTask.notes.find(
          el => el.id === currentTab.value
        ).text || "Нет заметок"
      )
    );

    const switchAssessmentTab = inject("switchAssessmentTab");

    return {
      currentTab,
      selectTab,
      tabs,
      currentText,
      switchAssessmentTab,
    };
  },
  components: {
    Nav,
  },
};
</script>
