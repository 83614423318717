<template>
  <div>
    <v-row>
      <v-col sm="6" cols="12">
        <v-card elevation="4" height="70vh">
          <expansion-list
            v-if="!isEmptyCompetences"
            :items="task.competences"
            :multiple="false"
            :additionalEspansion="competenceIndex"
          >
            <template v-slot:header="{ item }">
              <div>{{ item.indexNumber }}. {{ item.name }}</div>
            </template>
            <template v-slot:content="{ item }">
              <v-list v-if="!!item.indicators" class="py-0">
                <v-list-item
                  class="with-separator-shadow"
                  :class="{
                    'indicator--active': selectedIndicator.id == indicator.id,
                  }"
                  v-for="indicator in item.indicators"
                  :key="indicator.id"
                  @click="selectIndicator(indicator)"
                >
                  <div>
                    {{ item.indexNumber }}.{{ indicator.indexNumber }}
                    {{ indicator.name }}
                  </div>
                </v-list-item>
              </v-list>
              <div v-else class="title pa-3">Индикаторы отсутствуют</div>
            </template>
          </expansion-list>
          <div v-else class="pa-3">Компетенции отсутствуют</div>
        </v-card>
      </v-col>
      <v-col sm="6" cols="12">
        <v-card
          class="pa-3"
          elevation="4"
          height="70vh"
          color="#efefef"
          style="overflow: auto"
        >
          <h3 class="subtitle mt-0 mb-2">Оценка по шкале</h3>
          <div>
            <p
              v-for="marks in selectedIndicator.levelMarks"
              :key="marks.id"
              class="mb-4"
            >
              <span>{{ marks.level }}</span> - {{ marks.description }}
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-space-between mx-0 mt-2">
      <div></div>
      <v-btn
        class="white--text primary"
        min-width="220px"
        @click="switchAssessmentTab('Choise')"
      >
        К оценке индикаторов
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, inject } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const task = computed(() => store.getters.getCurrentAssessmentTask);

    const isEmptyCompetences = computed(() => !task.value.competences?.[0].id);

    const competenceIndex = computed(
      () =>
        task.value.competences?.findIndex(
          el => el.id === store.getters.getSelectedCompetence?.id
        ) || 0
    );

    const selectedIndicator = ref(task.value.competences[0]?.indicators?.[0]);
    const selectIndicator = ind => (selectedIndicator.value = ind);

    const switchAssessmentTab = inject("switchAssessmentTab");

    return {
      task,
      competenceIndex,
      selectedIndicator,
      selectIndicator,
      isEmptyCompetences,
      switchAssessmentTab,
    };
  },
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
  color: $mainColor;
}
</style>
