var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"overflow":"auto"},attrs:{"elevation":"4","height":"400"}},[(!_vm.isEmptyCompetences)?_c('expansion-list',{attrs:{"items":_vm.task.competences,"multiple":false,"additionalEspansion":_vm.competenceIndex},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_vm._v(_vm._s(item.indexNumber)+". "+_vm._s(item.name))]),_c('div',[(
              item.indicators.every(function (el) { return el.estimatedLevelMarkId !== null; })
            )?_c('v-icon',[_vm._v(" mdi-check-decagram ")]):_vm._e()],1)])]}},{key:"content",fn:function(ref){
            var item = ref.item;
return [(!!item.indicators)?_c('v-list',{staticClass:"py-0"},_vm._l((item.indicators),function(indicator){return _c('v-list-item',{key:indicator.id,class:{
            'with-separator-shadow': true,
            'indicator--active': _vm.selectedIndicator.id == indicator.id,
          },on:{"click":function($event){return _vm.selectIndicator(indicator, item)}}},[_c('div',{staticClass:"d-flex justify-space-between",style:({
              width: '100%',
            })},[_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.indexNumber)+"."+_vm._s(indicator.indexNumber)+" "+_vm._s(indicator.name)+" ")]),_c('div',[(indicator.estimatedLevelMarkId)?_c('v-icon',[_vm._v(" mdi-check-decagram ")]):_vm._e()],1)])])}),1):_vm._e()]}}],null,false,324741201)}):_c('div',{staticClass:"title pa-3"},[_vm._v("Компетенции отсутствуют")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }