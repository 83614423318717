<template>
  <v-card elevation="4" height="400" style="overflow: auto">
    <expansion-list
      v-if="!isEmptyCompetences"
      :items="task.competences"
      :multiple="false"
      :additionalEspansion="competenceIndex"
    >
      <template v-slot:header="{ item }">
        <div class="d-flex justify-space-between align-center">
          <div>{{ item.indexNumber }}. {{ item.name }}</div>
          <div>
            <v-icon
              v-if="
                item.indicators.every(el => el.estimatedLevelMarkId !== null)
              "
            >
              mdi-check-decagram
            </v-icon>
          </div>
        </div>
      </template>
      <template v-slot:content="{ item }">
        <v-list v-if="!!item.indicators" class="py-0">
          <v-list-item
            v-for="indicator in item.indicators"
            :key="indicator.id"
            :class="{
              'with-separator-shadow': true,
              'indicator--active': selectedIndicator.id == indicator.id,
            }"
            @click="selectIndicator(indicator, item)"
          >
            <div
              class="d-flex justify-space-between"
              :style="{
                width: '100%',
              }"
            >
              <div class="d-flex">
                {{ item.indexNumber }}.{{ indicator.indexNumber }}
                {{ indicator.name }}
              </div>
              <div>
                <v-icon v-if="indicator.estimatedLevelMarkId">
                  mdi-check-decagram
                </v-icon>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </template>
    </expansion-list>
    <div v-else class="title pa-3">Компетенции отсутствуют</div>
  </v-card>
</template>

<script>
import { ref, computed, watch, inject } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const task = computed(() => store.getters.getCurrentAssessmentTask);

    const currentIndex = ref(0);
    watch(
      () => currentIndex.value,
      () =>
        store.commit(
          "setSelectedCompetence",
          task.value.competences[currentIndex.value]
        )
    );

    const competenceIndex = computed(
      () =>
        task.value.competences?.findIndex(
          el => el.id === store.getters.getSelectedCompetence?.id
        ) || 0
    );
    watch(
      () => competenceIndex.value,
      val => (currentIndex.value = val)
    );

    const selectedIndicator = computed(
      () => store.getters.getSelectedIndicator || {}
    );
    const selectIndicator = (ind, comp) => {
      store.commit("setSelectedIndicator", ind);
      store.commit("setSelectedCompetence", comp);
    };

    return {
      isEmptyCompetences: inject("isEmptyCompetences"),
      task,
      currentIndex,
      selectedIndicator,
      selectIndicator,
      competenceIndex,
    };
  },
};
</script>
