<template>
  <div>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          height="27vw"
          color="black"
          rounded="0"
          style="overflow: hidden"
        >
          <iframe
            v-if="task.videoRecordLink"
            style="width: 100%; height: 100%"
            :src="task.videoRecordLink"
            frameborder="0"
            allow="autoplay"
          ></iframe>
          <div
            v-else
            class="d-flex align-center justify-center text-center h-100"
          >
            <p class="hint">Видео не прикреплено</p>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="27vw" class="d-flex flex-column">
          <div class="d-flex justify-space-between">
            <Nav
              class="mt-3"
              style="padding-left: 0 !important"
              :tabs="tabs"
              :currentTab="currentTab"
              @tab-selected="selectTab"
            />
            <div v-if="isCommentChanged" style="min-width: 72px">
              <v-btn icon @click="resetComment">
                <v-icon color="#767676">mdi-close</v-icon>
              </v-btn>
              <v-btn icon @click="updateComment">
                <v-icon color="#767676">mdi-check</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="flex-grow-1 d-flex">
            <v-textarea
              v-show="currentTab === 'video'"
              class="pa-3 mt-2 flex-grow-1 align-self-stretch"
              loading="false"
              hide-details
              no-resize
              v-model="videoComment"
              placeholder="Напишите комментарий"
            />
            <v-textarea
              v-show="currentTab === 'report'"
              class="pa-3 mt-0"
              loading="false"
              hide-details
              :readonly="!task.videoRecordLink"
              no-resize
              v-model="reportComment"
              placeholder="Напишите комментарий для отчета"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-space-between mx-0 mt-2">
      <div></div>
      <v-btn
        class="white--text primary"
        min-width="220px"
        @click="switchAssessmentTab('Choise')"
      >
        К оценке индикаторов
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, inject } from "@vue/composition-api";

import { useTabs } from "@/use/helpers";

import Nav from "@/components/shared/assets/Nav";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const task = computed(() => store.getters.getCurrentAssessmentTask);
    const sessionId = computed(() => store.getters.getSessionId);

    const tabs = [
      {
        name: "video",
        label: "Комментарии по видео",
      },
      {
        name: "report",
        label: "Комментарии для отчета",
      },
    ];

    const { currentTab, selectTab } = useTabs("video");

    const videoComment = ref(task.value.videoComment);
    const reportComment = ref(task.value.videoCommentForReport);

    const isCommentChanged = computed(
      () =>
        (currentTab.value === "video" &&
          videoComment.value !== task.value.videoComment) ||
        (currentTab.value === "report" &&
          reportComment.value !== task.value.videoCommentForReport)
    );

    const resetComment = () => {
      switch (currentTab.value) {
        case "video": {
          videoComment.value = task.value.videoComment;
          return;
        }
        case "report": {
          reportComment.value = task.value.videoCommentForReport;
          return;
        }
      }
    };

    const updateComment = () => {
      switch (currentTab.value) {
        case "video": {
          store.dispatch("setVideoComment", {
            taskId: task.value.id,
            sessionId: sessionId.value,
            comment: videoComment.value,
          });
          return;
        }
        case "report": {
          store.dispatch("setVideoCommentForReport", {
            taskId: task.value.id,
            sessionId: sessionId.value,
            comment: reportComment.value,
          });
          return;
        }
      }
    };

    const switchAssessmentTab = inject("switchAssessmentTab");

    return {
      task,
      sessionId,
      tabs,
      currentTab,
      selectTab,
      videoComment,
      reportComment,
      isCommentChanged,
      resetComment,
      updateComment,
      switchAssessmentTab,
    };
  },
  components: {
    Nav,
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-text-field__slot
  align-self: stretch
</style>
