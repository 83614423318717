<template>
  <v-card elevation="4" height="400" class="py-3 d-flex flex-column">
    <Nav
      v-if="!isEmptyCompetences"
      class="mb-5"
      :tabs="tabs"
      :currentTab="currentTab"
      @tab-selected="selectTab"
    />
    <p
      v-if="!isEmptyCompetences"
      class="pa-3"
      style="overflow-y: auto"
      v-html="replacer(text)"
    />
  </v-card>
</template>

<script>
import { computed, inject } from "@vue/composition-api";
import { useTabs } from "@/use/helpers";
import { useLineBreakReplacer } from "@/use/helpers";

import Nav from "@/components/shared/assets/Nav";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const tabs = [
      {
        name: "generalEmailReply",
        label: "Ответ на письмо",
      },
      {
        name: "videoComment",
        label: "Комментарии по видео",
      },
    ];
    const { currentTab, selectTab } = useTabs(tabs[0].name);
    const { replacer } = useLineBreakReplacer();

    const text = computed(
      () => store.getters.getCurrentAssessmentTask[currentTab.value]
    );

    return {
      isEmptyCompetences: inject("isEmptyCompetences"),
      tabs,
      currentTab,
      selectTab,
      replacer,
      text,
    };
  },
  components: { Nav },
};
</script>
