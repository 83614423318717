<template>
  <v-card elevation="4" height="400">
    <div
      v-if="!isEmptyCompetences"
      class="px-3 pt-3 d-flex justify-space-between"
    >
      <Nav
        class="mb-5"
        :tabs="tabs"
        :currentTab="currentTab"
        @tab-selected="switchTab"
      />
      <div v-if="isCommentChanged">
        <v-btn icon @click="resetComment">
          <v-icon color="#767676">mdi-close</v-icon>
        </v-btn>
        <v-btn icon @click="updateComment">
          <v-icon color="#767676">mdi-check</v-icon>
        </v-btn>
      </div>
    </div>
    <v-textarea
      v-if="!isEmptyCompetences"
      v-show="selectedTab.name == 'indicator'"
      solo
      hide-details
      class="pb-1 px-1 textarea--solo"
      style="min-height: 30% !important; height: calc(100% - 50px)"
      :no-resize="true"
      v-model="indicatorText"
      label="Напишите комментарий по индикатору"
    />
    <v-textarea
      v-if="!isEmptyCompetences"
      v-show="selectedTab.name == 'additional'"
      solo
      hide-details
      class="pb-1 px-1 textarea--solo"
      style="min-height: 30% !important; height: calc(100% - 50px)"
      :no-resize="true"
      v-model="additionalText"
      label="Напишите дополнительный комментарий"
    />
  </v-card>
</template>

<script>
import { ref, computed, watch, inject } from "@vue/composition-api";

import { useTabs } from "@/use/helpers";

import Nav from "@/components/shared/assets/Nav";

export default {
  props: ["sessionId"],
  setup(_, { root }) {
    const store = root.$store;

    const loading = inject("loading");

    const tabs = [
      {
        name: "indicator",
        label: "Комментарии по индикатору",
        saveMethod: "setSessionIndicatorComment",
      },
      {
        name: "additional",
        label: "Доп. комментарии",
        saveMethod: "setSessionIndicatorAdditionalComment",
      },
    ];
    const { currentTab, selectTab } = useTabs(tabs[0].name);

    const selectedTab = ref(tabs[0]);
    const switchTab = tabName => {
      selectedTab.value = tabs.find(el => el.name === tabName);
      selectTab(tabName);
    };

    const selectedIndicator = computed(
      () => store.getters.getSelectedIndicator || {}
    );

    const indicatorText = ref(selectedIndicator.value.comment);
    const initialIndicatorText = ref(selectedIndicator.value.comment || "");
    const additionalText = ref(selectedIndicator.value.additionalComment);

    // Comment auto update on mark changed
    watch(
      () => selectedIndicator.value.comment,
      val => {
        if (
          !indicatorText.value ||
          selectedIndicator.value.levelMarks
            .map(el => el.description)
            .includes(indicatorText.value)
        )
          indicatorText.value = val;
      }
    );

    const isCommentChanged = computed(
      () =>
        (selectedTab.value.name === "indicator" &&
          indicatorText.value !== initialIndicatorText.value) ||
        (selectedTab.value.name === "additional" &&
          additionalText.value !== selectedIndicator.value.additionalComment)
    );
    const resetComment = () => {
      switch (selectedTab.value.name) {
        case "indicator": {
          indicatorText.value = initialIndicatorText.value;
          break;
        }
        case "additional":
          additionalText.value = selectedIndicator.value.additionalComment;
      }
    };
    const updateComment = async () => {
      loading.value = true;
      await store.dispatch(selectedTab.value.saveMethod, {
        taskId: store.getters.getCurrentAssessmentTask.id,
        profileTaskIndicatorId: selectedIndicator.value.id,
        sessionId: _.sessionId,
        text:
          currentTab.value === "indicator"
            ? indicatorText.value
            : additionalText.value,
      });

      if (currentTab.value === "indicator")
        initialIndicatorText.value = indicatorText.value;
      loading.value = false;
    };

    watch(
      () => selectedIndicator.value,
      () => {
        selectedTab.value = tabs[0];
        currentTab.value = selectedTab.value.name;
        indicatorText.value = selectedIndicator.value.comment;
        initialIndicatorText.value = selectedIndicator.value.comment;
        additionalText.value = selectedIndicator.value.additionalComment;
      }
    );

    return {
      isEmptyCompetences: inject("isEmptyCompetences"),
      tabs,
      currentTab,
      switchTab,
      selectedTab,
      indicatorText,
      initialIndicatorText,
      additionalText,
      isCommentChanged,
      resetComment,
      updateComment,
    };
  },
  components: { Nav },
};
</script>
