var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({
      maxWidth: '600px',
    })},[_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Краткое содержание:")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.replacer(_vm.task.task.description))}})]),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Инструкции:")]),_c('v-card',{style:({
          maxHeight: '300px',
          overflowY: 'auto',
        })},[(_vm.task.task.instructions && _vm.task.task.instructions.length)?_c('expansion-list',{attrs:{"items":_vm.task.task.instructions,"multiple":false},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"content",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"pa-3"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.replacer(item.text))}}),_c('div',{staticClass:"mt-5 mx-1 brd-a",staticStyle:{"border-radius":"6px"}},[_c('v-list',{staticClass:"py-0",style:({
                    backgroundColor: 'transparent',
                  })},[[_c('div',{staticClass:"my-1 px-3",staticStyle:{"min-height":"24px"}},[_c('a',{style:({
                          width: '100%',
                        }),attrs:{"href":item.attachmentLink,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-1 mb-1",staticStyle:{"width":"15px","height":"15px"}},[_c('img',{attrs:{"src":require('@/assets/img/document2.svg')}})]),_c('v-list-item-content',{staticClass:"py-1"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(item.attachmentName)+" ")])])],1)])])]],2)],1)])]}}],null,false,2586929707)}):_c('p',{staticClass:"pa-3"},[_vm._v("Инструкций нет")])],1)],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Письма:")]),_c('v-card',{style:({
          maxHeight: '400px',
          overflowY: 'auto',
        })},[(_vm.task.task.emails && _vm.task.task.emails.length)?_c('expansion-list',{attrs:{"items":_vm.task.task.emails,"multiple":false},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"flex-grow-1 pr-2"},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.id == _vm.task.task.generalEmailId)?_c('v-icon',{style:({
                  color: '#fd3018 !important',
                }),attrs:{"small":""}},[_vm._v("mdi-exclamation-thick")]):_vm._e(),_c('v-icon',{style:({
                  color: item.important
                    ? '#fd3018 !important'
                    : '#a9a9aa !important',
                }),attrs:{"small":""}},[_vm._v("mdi-bookmark")])],1)]}},{key:"content",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"d-flex"},[_c('p',[_c('b',[_vm._v("От:")]),_vm._v(" "+_vm._s(item.from))])]),(item.copy.length)?_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"flex-shrink-0"},[_c('b',[_vm._v("Копия:")])]),_c('p',{staticClass:"px-1 flex-grow-1"},[_vm._v(" "+_vm._s(item.copy.filter(Boolean).join("; "))+" ")])]):_vm._e()]),(false)?_c('div',{staticClass:"text-right"},[_c('p',[_vm._v(_vm._s(_vm.digitDate(item.receiptTime)))]),_c('p',[_vm._v(_vm._s(_vm.digitTime(item.receiptTime)))])]):_vm._e()]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.replacer(item.text))}}),(item.documents && item.documents.length)?_c('div',{staticClass:"mt-5 mx-1 brd-a",staticStyle:{"border-radius":"6px"}},[_c('v-list',{staticClass:"py-0",style:({
                    backgroundColor: 'transparent',
                  })},[_vm._l((item.documents),function(doc){return [_c('div',{key:doc.id,staticClass:"my-1 px-3",staticStyle:{"min-height":"24px"}},[_c('a',{style:({
                          width: '100%',
                        }),attrs:{"href":doc.link,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-1 mb-1",staticStyle:{"width":"15px","height":"15px"}},[_c('img',{attrs:{"src":require('@/assets/img/document2.svg')}})]),_c('v-list-item-content',{staticClass:"py-1"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(doc.name))])])],1)])])]})],2)],1):_vm._e()])]}}],null,false,1922265543)}):_c('p',{staticClass:"pa-3"},[_vm._v("Письма не выбраны")])],1)],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Встречи:")]),_c('v-card',{style:({
          maxHeight: '400px',
          overflowY: 'auto',
        })},[(_vm.task.task.meetings && _vm.task.task.meetings.length)?_c('expansion-list',{attrs:{"items":_vm.task.task.meetings,"multiple":false},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.title))])]}},{key:"content",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"d-flex"},[_c('p',[_c('b',[_vm._v("От:")]),_vm._v(" "+_vm._s(item.from))])]),(item.copy.length)?_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"flex-shrink-0"},[_c('b',[_vm._v("Копия:")])]),_c('p',{staticClass:"px-1 flex-grow-1"},[_vm._v(" "+_vm._s(item.copy.filter(Boolean).join("; "))+" ")])]):_vm._e()]),(false)?_c('div',{staticClass:"text-right"},[_c('p',[_vm._v(_vm._s(_vm.digitDate(item.date)))]),_c('p',[_vm._v(_vm._s(item.startTime)+" - "+_vm._s(item.endTime))])]):_vm._e()]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.replacer(item.text))}}),(item.documents && item.documents.length)?_c('div',{staticClass:"mt-5 mx-1 brd-a",staticStyle:{"border-radius":"6px"}},[_c('v-list',{staticClass:"py-0",style:({
                    backgroundColor: 'transparent',
                  })},[_vm._l((item.documents),function(doc){return [_c('div',{key:doc.id,staticClass:"my-1 px-3",staticStyle:{"min-height":"24px"}},[_c('a',{style:({
                          width: '100%',
                        }),attrs:{"href":doc.link,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-1 mb-1",staticStyle:{"width":"15px","height":"15px"}},[_c('img',{attrs:{"src":require('@/assets/img/document2.svg')}})]),_c('v-list-item-content',{staticClass:"py-1"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(doc.name))])])],1)])])]})],2)],1):_vm._e()])]}}],null,false,2370532347)}):_c('p',{staticClass:"pa-3"},[_vm._v("Встречи не выбраны")])],1)],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Документы:")]),_c('v-card',{style:({
          maxHeight: '253px',
          overflowY: 'auto',
        })},[(_vm.task.task.documents && _vm.task.task.documents.length)?_c('v-list',_vm._l((_vm.task.task.documents),function(item){return _c('v-list-item',{key:item.id,staticClass:"with-separator-shadow",staticStyle:{"cursor":"default"}},[_c('a',{staticClass:"tab-link tab-link--reverse my-1",staticStyle:{"padding":"0 5px"},attrs:{"href":item.link,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticStyle:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" "+_vm._s(item.name)+" ")])])])])}),1):_c('p',{staticClass:"pa-3"},[_vm._v("Документов нет")])],1)],1)]),_c('div',{staticClass:"d-flex justify-end py-2"},[_c('v-btn',{staticClass:"white--text primary",attrs:{"min-width":"220px"},on:{"click":function($event){return _vm.switchAssessmentTab('Choise')}}},[_vm._v(" К оценке индикаторов ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }