<template>
  <div>
    <div
      :style="{
        maxWidth: '600px',
      }"
    >
      <div class="mb-3">
        <p class="subtitle primary--text mb-1">Краткое содержание:</p>
        <p v-html="replacer(task.task.description)"></p>
      </div>

      <div class="mb-3">
        <p class="subtitle primary--text mb-1">Инструкции:</p>
        <v-card
          :style="{
            maxHeight: '300px',
            overflowY: 'auto',
          }"
        >
          <expansion-list
            v-if="task.task.instructions && task.task.instructions.length"
            :items="task.task.instructions"
            :multiple="false"
          >
            <template v-slot:header="{ item }">
              <p>
                {{ item.name }}
              </p>
            </template>
            <template v-slot:content="{ item }">
              <div class="pa-3">
                <p v-html="replacer(item.text)"></p>
                <div class="mt-5 mx-1 brd-a" style="border-radius: 6px">
                  <v-list
                    :style="{
                      backgroundColor: 'transparent',
                    }"
                    class="py-0"
                  >
                    <template>
                      <div class="my-1 px-3" style="min-height: 24px">
                        <a
                          :href="item.attachmentLink"
                          target="_blank"
                          :style="{
                            width: '100%',
                          }"
                        >
                          <div class="d-flex align-center">
                            <div
                              class="mr-1 mb-1"
                              style="width: 15px; height: 15px"
                            >
                              <img
                                :src="require('@/assets/img/document2.svg')"
                              />
                            </div>

                            <v-list-item-content class="py-1">
                              <p style="font-size: 14px">
                                {{ item.attachmentName }}
                              </p>
                            </v-list-item-content>
                          </div>
                        </a>
                      </div>
                    </template>
                  </v-list>
                </div>
              </div>
            </template>
          </expansion-list>
          <p v-else class="pa-3">Инструкций нет</p>
        </v-card>
      </div>

      <div class="mb-3">
        <p class="subtitle primary--text mb-1">Письма:</p>
        <v-card
          :style="{
            maxHeight: '400px',
            overflowY: 'auto',
          }"
        >
          <expansion-list
            v-if="task.task.emails && task.task.emails.length"
            :items="task.task.emails"
            :multiple="false"
          >
            <template v-slot:header="{ item }">
              <div class="d-flex align-center">
                <p class="flex-grow-1 pr-2">
                  {{ item.title }}
                </p>
                <v-icon
                  v-if="item.id == task.task.generalEmailId"
                  :style="{
                    color: '#fd3018 !important',
                  }"
                  small
                  >mdi-exclamation-thick</v-icon
                >
                <v-icon
                  :style="{
                    color: item.important
                      ? '#fd3018 !important'
                      : '#a9a9aa !important',
                  }"
                  small
                  >mdi-bookmark</v-icon
                >
              </div>
            </template>
            <template v-slot:content="{ item }">
              <div class="pa-3">
                <div class="d-flex justify-space-between">
                  <div class="mb-5">
                    <div class="d-flex">
                      <p><b>От:</b> {{ item.from }}</p>
                    </div>
                    <div class="d-flex" v-if="item.copy.length">
                      <p class="flex-shrink-0"><b>Копия:</b></p>
                      <p class="px-1 flex-grow-1">
                        {{ item.copy.filter(Boolean).join("; ") }}
                      </p>
                    </div>
                  </div>
                  <div class="text-right" v-if="false">
                    <p>{{ digitDate(item.receiptTime) }}</p>
                    <p>{{ digitTime(item.receiptTime) }}</p>
                  </div>
                </div>
                <p v-html="replacer(item.text)"></p>
                <div
                  v-if="item.documents && item.documents.length"
                  class="mt-5 mx-1 brd-a"
                  style="border-radius: 6px"
                >
                  <v-list
                    :style="{
                      backgroundColor: 'transparent',
                    }"
                    class="py-0"
                  >
                    <template v-for="doc in item.documents">
                      <div
                        class="my-1 px-3"
                        style="min-height: 24px"
                        :key="doc.id"
                      >
                        <a
                          :href="doc.link"
                          target="_blank"
                          :style="{
                            width: '100%',
                          }"
                        >
                          <div class="d-flex align-center">
                            <div
                              class="mr-1 mb-1"
                              style="width: 15px; height: 15px"
                            >
                              <img
                                :src="require('@/assets/img/document2.svg')"
                              />
                            </div>

                            <v-list-item-content class="py-1">
                              <p style="font-size: 14px">{{ doc.name }}</p>
                            </v-list-item-content>
                          </div>
                        </a>
                      </div>
                    </template>
                  </v-list>
                </div>
              </div>
            </template>
          </expansion-list>
          <p v-else class="pa-3">Письма не выбраны</p>
        </v-card>
      </div>

      <div class="mb-3">
        <p class="subtitle primary--text mb-1">Встречи:</p>
        <v-card
          :style="{
            maxHeight: '400px',
            overflowY: 'auto',
          }"
        >
          <expansion-list
            v-if="task.task.meetings && task.task.meetings.length"
            :items="task.task.meetings"
            :multiple="false"
          >
            <template v-slot:header="{ item }">
              <p>{{ item.title }}</p>
            </template>
            <template v-slot:content="{ item }">
              <div class="pa-3">
                <div class="d-flex justify-space-between">
                  <div class="mb-5">
                    <div class="d-flex">
                      <p><b>От:</b> {{ item.from }}</p>
                    </div>
                    <div v-if="item.copy.length" class="d-flex">
                      <p class="flex-shrink-0"><b>Копия:</b></p>
                      <p class="px-1 flex-grow-1">
                        {{ item.copy.filter(Boolean).join("; ") }}
                      </p>
                    </div>
                  </div>
                  <div class="text-right" v-if="false">
                    <p>{{ digitDate(item.date) }}</p>
                    <p>{{ item.startTime }} - {{ item.endTime }}</p>
                  </div>
                </div>
                <p v-html="replacer(item.text)"></p>
                <div
                  v-if="item.documents && item.documents.length"
                  class="mt-5 mx-1 brd-a"
                  style="border-radius: 6px"
                >
                  <v-list
                    :style="{
                      backgroundColor: 'transparent',
                    }"
                    class="py-0"
                  >
                    <template v-for="doc in item.documents">
                      <div
                        class="my-1 px-3"
                        style="min-height: 24px"
                        :key="doc.id"
                      >
                        <a
                          :href="doc.link"
                          target="_blank"
                          :style="{
                            width: '100%',
                          }"
                        >
                          <div class="d-flex align-center">
                            <div
                              class="mr-1 mb-1"
                              style="width: 15px; height: 15px"
                            >
                              <img
                                :src="require('@/assets/img/document2.svg')"
                              />
                            </div>

                            <v-list-item-content class="py-1">
                              <p style="font-size: 14px">{{ doc.name }}</p>
                            </v-list-item-content>
                          </div>
                        </a>
                      </div>
                    </template>
                  </v-list>
                </div>
              </div>
            </template>
          </expansion-list>
          <p v-else class="pa-3">Встречи не выбраны</p>
        </v-card>
      </div>

      <div class="mb-3">
        <p class="subtitle primary--text mb-1">Документы:</p>
        <v-card
          :style="{
            maxHeight: '253px',
            overflowY: 'auto',
          }"
        >
          <v-list v-if="task.task.documents && task.task.documents.length">
            <v-list-item
              class="with-separator-shadow"
              style="cursor: default"
              v-for="item in task.task.documents"
              :key="item.id"
            >
              <a
                class="tab-link tab-link--reverse my-1"
                style="padding: 0 5px"
                :href="item.link"
                target="_blank"
              >
                <div class="d-flex align-center">
                  <p style="color: rgba(0, 0, 0, 0.87)">
                    {{ item.name }}
                  </p>
                </div>
              </a>
            </v-list-item>
          </v-list>
          <p v-else class="pa-3">Документов нет</p>
        </v-card>
      </div>
    </div>

    <div class="d-flex justify-end py-2">
      <v-btn
        class="white--text primary"
        min-width="220px"
        @click="switchAssessmentTab('Choise')"
      >
        К оценке индикаторов
      </v-btn>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "@vue/composition-api";

import { useLineBreakReplacer, useFormattedDates } from "@/use/helpers";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const task = computed(() => store.getters.getCurrentAssessmentTask);

    const { replacer } = useLineBreakReplacer();
    const { digitDate, digitTime } = useFormattedDates();

    const switchAssessmentTab = inject("switchAssessmentTab");

    return {
      task,
      replacer,
      digitDate,
      digitTime,
      switchAssessmentTab,
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  .v-list-item {
    padding: 0 10px !important;
  }
}
</style>
